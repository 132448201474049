var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('BlurredTenant',{attrs:{"showBlur":_vm.showBlur}},[_c('x-default-data-table',{attrs:{"x-options":_vm.tableOptions,"x-context":_vm.self,"x-container":_vm.tableContainer},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0px !important"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"px-4 py-4 grey lighten-3"},[_c('connectors',{attrs:{"item":item}})],1)])]}},{key:"item.connectors",fn:function(ref){
var item = ref.item;
return [(item.details && item.details.connectors)?_c('div',_vm._l((item.details.connectors),function(connector){return _c('v-chip',{key:connector.connectorId,staticClass:"mr-2 my-1",attrs:{"color":(['Available'].includes(connector.status) && 'success') ||
            (['Unavailable', 'Offline'].includes(connector.status) &&
                'error') ||
            'info'}},[_c('v-avatar',{staticClass:"white black--text",attrs:{"left":""}},[_vm._v(" "+_vm._s(connector.connectorId)+" ")]),_vm._v(" "+_vm._s(connector.status)+" ")],1)}),1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }